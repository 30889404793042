

import { GLOBAL_URL } from "./actionsAuth"
import axios from "axios";

import { 
    setNotification,
    setLoading,
    getAccessToken
 } from "./actions"

 import {HORUS_CK} from '../econf/cnf'

 import { authRefresh } from "./actionsAuth"
 import { MODIFY_INTERFACE_PARAM } from "./actions"

 export const ON_LOAD_PROFILE = "ON_LOAD_PROFILE"
 export const ON_LOAD_PRODUCT_CONFIG = "ON_LOAD_PRODUCT_CONFIG"
 export const ON_LOAD_HOLIDAYS = "ON_LOAD_HOLIDAYS"
 export const ON_LOAD_CONTACT_CONFIG = "ON_LOAD_CONTACT_CONFIG"
 export const ON_LOAD_USERS = "ON_LOAD_USERS"
 export const ON_LOAD_TASKS = "ON_LOAD_TASKS"
 export const ON_LOAD_TASK_STEPS = "ON_LOAD_TASK_STEPS"
 export const ON_LOAD_TASK_DETAILS = "ON_LOAD_TASK_DETAILS"
 export const ON_LOAD_SALES = "ON_LOAD_SALES"
 export const ON_LOAD_PUBLIC_SALES = "ON_LOAD_PUBLIC_SALES"
 export const ON_LOAD_CONTACT = "ON_LOAD_CONTACT"
 export const ON_LOAD_TAGS_CONFIG = "ON_LOAD_TAGS_CONFIG"
 export const ON_LOAD_OLD_CONTACTS = "ON_LOAD_OLD_CONTACTS"
 export const ON_LOAD_DASHBOARD = "ON_LOAD_DASHBOARD"
 export const ON_LOAD_ANNOUNCEMENTS = "ON_LOAD_ANNOUNCEMENTS"
 export const ON_LOAD_EVENT = "ON_LOAD_EVENT"
 export const ON_LOAD_EDITION = "ON_LOAD_EDITION"
 export const ON_LOAD_MAIL = "ON_LOAD_MAIL"
 export const ON_LOAD_NEWSLETTER = "ON_LOAD_NEWSLETTER"

export function apiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator = false) {
    return dispatch => {
        if (!withoutLoadingIndicator)
            dispatch(setLoading(true))
        return fetch(GLOBAL_URL + "api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({params:arguments}))
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (!result.error && extraExecOnSuccess)
                    {
                        if (extraExecOnSuccess.constructor === Array)
                            for (var extra of extraExecOnSuccess)
                                dispatch (extra)
                        else
                            dispatch(extraExecOnSuccess)
                    }
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                },
                (error) => {
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                }
            )
    }
}

export function treatGenericResponse (response, dispatch) {
    if (response.error && (response.error.length > 0))
        dispatch(setNotification ("Error occured.", response.error))
    if (response.notification && (response.notification.length > 0))
        dispatch(setNotification ("Notification", response.notification))
    if (response.profile)
        dispatch({type: ON_LOAD_PROFILE, profile:response.profile})
    if (response.productConfig)
        dispatch({type: ON_LOAD_PRODUCT_CONFIG, productConfig:response.productConfig})
    if (response.contactConfig)
        dispatch({type: ON_LOAD_CONTACT_CONFIG, contactConfig:response.contactConfig})
    if (response.users)
        dispatch({type: ON_LOAD_USERS, users: response.users})
    if (response.tasks)
        dispatch({type: ON_LOAD_TASKS, tasks: response.tasks})
    if (response.taskSteps)
        dispatch({type: ON_LOAD_TASK_STEPS, taskSteps: response.taskSteps})
    if (response.taskDetails)
        dispatch({type: ON_LOAD_TASK_DETAILS, taskDetails: response.taskDetails})
    if (response.sales)
        dispatch({type: ON_LOAD_SALES, sales: response.sales})
    if (response.publicSales)
        dispatch({type: ON_LOAD_PUBLIC_SALES, publicSales: response.publicSales})
    if (response.interfaceAction)
        dispatch({type:MODIFY_INTERFACE_PARAM, "paramName": response.interfaceAction.paramName, "paramValue": response.interfaceAction.paramValue})
    if (response.openContact)
        dispatch({type:ON_LOAD_CONTACT, openContact: response.openContact})
    if (response.openEvent)
        dispatch({type:ON_LOAD_EVENT, openEvent: response.openEvent})
    if (response.openEdition)
        dispatch({type:ON_LOAD_EDITION, openEdition: response.openEdition})
    if (response.tagsConfig)
        dispatch({type:ON_LOAD_TAGS_CONFIG, tagsConfig: response.tagsConfig})
    if (response.oldContactSuggestions)
        dispatch({type:ON_LOAD_OLD_CONTACTS, oldContactSuggestions: response.oldContactSuggestions})
    if (response.dashboard)
        dispatch({type:ON_LOAD_DASHBOARD, dashboard: response.dashboard})
    if (response.announcements)
        dispatch({type:ON_LOAD_ANNOUNCEMENTS, announcements: response.announcements})
    if (response.openMail)
        dispatch({type:ON_LOAD_MAIL, openMail: response.openMail})
    if (response.horusAuthMissing)
        dispatch(setNotification ("Notification", 'Horus authorisation needed at <a href="https://my-horus.com/fr/api/oauth2/authorize?client_id=' + HORUS_CK + '&response_type=code&redirect_uri=' + GLOBAL_URL + 'horus/success/" target="_blank">this link</a>'))
    if (response.stripeRedirect)
        dispatch({type:MODIFY_INTERFACE_PARAM, "paramName": "stripeRedirect", "paramValue": response.stripeRedirect})
    if (response.holidays)
        dispatch({type: ON_LOAD_HOLIDAYS, holidays:response.holidays})
    if (response.newslettersConfig)
        dispatch({type: ON_LOAD_NEWSLETTER, newslettersConfig:response.newslettersConfig})
    
}

export function apiRequest (body, extraExecOnSuccess = null, withoutLoadingIndicator = false) {
    const conf = {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return apiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator)   
}


export function apiRequestWithFiles (body, files, extraExecOnSuccess = null) {
    let httpClient = axios.create ({baseURL:GLOBAL_URL})
    let formData = new FormData();

    for (let fk in files)
        formData.append(fk, files[fk]);
    formData.append("otherParams", JSON.stringify(body))

    return dispatch => {
        dispatch(setLoading(true))
        return httpClient.post("filesApi", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + getAccessToken()
            },
            onUploadProgress: ((event) => {
                //dispatch(setLoading(true, Math.round((100 * event.loaded) / event.total)))
                })
            })
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({params:arguments}))
                return res.data})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (!result.error && extraExecOnSuccess)
                        dispatch(extraExecOnSuccess)
                    dispatch(setLoading(false))
                },
                (error) => {
                    dispatch(setLoading(false))
                }
            )
    }
}

export function getAttachmentForTaskStep (stepId, fname) {
    const conf = {
        method: "post",
        body: JSON.stringify({ "stepId":stepId, 'endpoint': 'stepAttachment' }),
        headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
      };
  
      return dispatch => {
          dispatch(setLoading(true))
          return fetch(GLOBAL_URL + "attachment", conf)
              .then(res => { 
                  if (res.status === 403) 
                      dispatch(authRefresh({params:arguments}))
                  return res.blob()})
              .then((blob) => {
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, fname);
                      dispatch(setLoading(false))
                  } else { //other browsers
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', fname);
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode.removeChild(link);
                      dispatch(setLoading(false))
                  }
                })
      }
}

export function getAttachmentForSale (saleId, fname, attachmentType='normal') {
    const conf = {
        method: "post",
        body: JSON.stringify({ "saleId":saleId, 'endpoint': 'saleAttachment' }),
        headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
      };
  
      return dispatch => {
          dispatch(setLoading(true))
          return fetch(GLOBAL_URL + "attachment", conf)
              .then(res => { 
                  if (res.status === 403) 
                      dispatch(authRefresh({params:arguments}))
                  return res.blob()})
              .then((blob) => {
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, fname);
                      dispatch(setLoading(false))
                  } else { //other browsers
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', fname);
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode.removeChild(link);
                      dispatch(setLoading(false))
                  }
                })
      }
}

export function getInvoiceForSale (saleId, fname) {
    const conf = {
        method: "post",
        body: JSON.stringify({ "saleId":saleId, 'endpoint': 'saleInvoice' }),
        headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
      };
  
      return dispatch => {
          dispatch(setLoading(true))
          return fetch(GLOBAL_URL + "attachment", conf)
              .then(res => { 
                  if (res.status === 403) 
                      dispatch(authRefresh({params:arguments}))
                  return res.blob()})
              .then((blob) => {
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, fname);
                      dispatch(setLoading(false))
                  } else { //other browsers
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', fname);
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode.removeChild(link);
                      dispatch(setLoading(false))
                  }
                })
      }
}


export function publicApiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator = false) {
    return dispatch => {
        if (!withoutLoadingIndicator)
            dispatch(setLoading(true))
        return fetch(GLOBAL_URL + "papi", conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (!result.error && extraExecOnSuccess)
                    {
                        if (extraExecOnSuccess.constructor === Array)
                            for (var extra of extraExecOnSuccess)
                                dispatch (extra)
                        else
                            dispatch(extraExecOnSuccess)
                    }
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                },
                (error) => {
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                }
            )
    }
}

export function publicApiRequest (body, extraExecOnSuccess = null, withoutLoadingIndicator = false) {
    const conf = {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({ "Content-Type": "application/json" })
    };
    return publicApiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator)   
}