import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import '../css/Notification.scss';

import {
    setNotification
} from '../redux/actions'

class Notification extends Component
{
    render () {
        var that = this
        if ((!this.props.interface.notificationHeader) && ((!this.props.interface.notificationContent)))
            return null
        
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch (setNotification())}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.interface.notificationHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: this.props.interface.notificationContent}} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e)=>{that.props.dispatch (setNotification())}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
    }
}

export default connect(mapStateToProps)(Notification)
