import { createRef, Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, ON_LOAD_EDITION, ON_LOAD_EVENT } from '../redux/actionsAPI';
import {LABEL_EVENT,LABEL_QNOTE} from '../econf/cnf'
import { productValueLabel } from './_tools';



class EventEditionBrowseModal extends Component {
    state =  Object.assign({ selectedTab: "Tasks" }, ...["firstName", 'lastName', "email", "jobTitle", "companyName", "regOption", "withCode"].map ((el) => ({ [el+"Filter"]:"" })))
    
    componentDidMount() {
        if (this.props.interface.browsingEditionId > 0)
        {
            this.props.dispatch ({type: ON_LOAD_EDITION, openEdition: {}})
            this.props.dispatch (apiRequest({'endpoint': 'getEventEdition', 'editionId': this.props.interface.browsingEditionId}))    
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    render () {
        var that = this
        var contents = null

        if (this.state.selectedTab === 'Registrations')
        {
            var lines = null;
            var cnt = 0
            if (that.props.openEdition && that.props.openEdition.registrations)
                lines = that.props.openEdition.registrations.map (function (p, ic) {
                    for (var fN of ["firstName", 'lastName', "email", "jobTitle", "companyName"])
                        if ((that.state[fN + "Filter"].length > 0) && ((! p.contact[fN]) || (! p.contact[fN].toLowerCase().includes (that.state[fN + "Filter"].toLowerCase())  )))
                            return null
                    for (var fN of ["regOption", "withCode"])
                        if ((that.state[fN + "Filter"].length > 0) && ((!p[fN]) || (! p[fN].toLowerCase().includes (that.state[fN + "Filter"].toLowerCase())  )))
                            return null

                    cnt += 1
                    
                    return <tr key={"reg"+ic} >
                                <td>{p.registrationDate}</td>
                                <td>{p.contact.firstName} </td>
                                <td>{p.contact.lastName} </td>
                                <td>{p.contact.email}</td>
                                <td>{p.contact.jobTitle}</td>
                                <td>{p.contact.companyName} {p.contact.country ? <span>({p.contact.country})</span>:null}</td>
                                <td>{p.regOption}</td>
                                <td>{p.withCode}</td>
                                {that.props.profile.isMarketing ?<td><Button onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'deleteRegistration', 'registrationId': p.id}))}}>Delete</Button></td>:null}
                            </tr>
                })


            contents = <div className="mt-3">
                <h3>Registrations ({cnt})</h3>
                <Table responsive className="table-hover" id="regTable">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>e-mail</th>
                            <th>Position</th>
                            <th>Company</th>
                            <th>Registration type</th>
                            <th>Used code</th>
                            {that.props.profile.isMarketing ?<td>Actions</td>:null}
                        </tr>
                        <tr>
                            <td></td>
                            {["firstName", "lastName", "email", "jobTitle", "companyName", "regOption", "withCode"].map (
                                function (fN, ic) {
                                    return <td key={"filt"+ic}>
                                        <input
                                            className="form-control"
                                            name={fN + "Filter"}
                                            onChange={that.handleInputChange}
                                            value={that.state[fN + "Filter"]}
                                            type="text"
                                        />
                                    </td>
                                }
                            )}
                        </tr>
                            
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
                <ReactHTMLTableToExcel
                    className="download-table-xls-button"
                    table="regTable"
                    filename="Registrations"
                    sheet={this.props.openEdition.fullName}
                    buttonText="Download as XLS"/>

            </div>
        }
        if (this.state.selectedTab === 'Sales')
        {
            var lines = null;
            if (that.props.openEdition && that.props.openEdition.idSales)
                lines = that.props.openEdition.idSales.map (function (pID, ic) {
                    var p = that.props.sales [pID]
                    if (!p)
                        return null
                    var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : { idCompany: 0}
                    return <tr key={"sale"+ic} role="button" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.id})}}>
                                <td></td>
                                <td>{p.transactionDate}</td>
                                <td>{p.id}</td>
                                <td>{p.products.map (function (pp, ic) { return pp.idProduct > 0 ? (productValueLabel(pp.idProduct, that.props.productConfig).label + " ") : "" }) } {p.oneTimeProduct ? p.oneTimeProduct : ""}</td>
                                <td>{p.agreedPrice}</td>
                                <td>{p.quantity}</td>
                                <td>{pContact.fullName}</td>
                                <td>{(p.idCompany > 0) ? that.props.contactConfig.companiesDic[p.idCompany].name : null}</td>
                                <td>{p.note}</td>
                                <td>{(p.idBy > 0) ? that.props.users[p.idBy].fullName : p.historicBy }</td>
                                <td>{p.invoiceRef}</td>
                                <td>{(p.invoicePaid || p.cashPaid) ? <i className={"fs-4 bi-check2"}></i>:(p.willCashPaid ? <i className={"fs-4 bi-hourglass"}></i>:null) }</td>
                                <td>{p.invoiceChecked ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                            </tr>
                })


            contents = <div className="mt-3">
                <h3>Sales</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>ID</th>
                            <th>Products</th>
                            <th>HTVA</th>
                            <th>QTE</th>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>{LABEL_QNOTE}</th>
                            <th>By</th>
                            <th>Invoice</th>
                            <th>Paid</th>
                            <th>CHK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Tasks')
        {
            var lines = null;
            if (that.props.openEdition && that.props.openEdition.idTasks)
                lines = that.props.openEdition.idTasks.map (function (pID, ic) {
                    var p = that.props.tasks[pID]
                    var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : { idCompany: 0}
                    return <tr className={p.hotState ? "task hot": "task"}  key={"task"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                        <td>{pContact.fullName}</td>
                        <td>{(pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany].name : null}</td>
                        <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).label : ""} </td>
                        {that.props.profile && that.props.profile.isProgram ? null:<td>{p.proposalAmount} {p.proposalDate}</td>}
                        {that.props.profile && that.props.profile.isProgram ? null:<td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>}
                        <td>{p.taskStep.label}</td>
                        <td>{p.nextActionDate}</td>
                        <td>{p.updateDate}</td>
                        <td>{(p.idUser > 0) ? that.props.users[p.idUser].fullName : null}</td>
                    </tr>
                })


            contents = <div className="mt-3">
                <h3>Tasks</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>Products</th>
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Proposal</th>}
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Is hot</th>}
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                            <th>Responsible</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }
        
        return <Modal className='eventEditionBrowserModal' size={'xl'}  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEditionId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{LABEL_EVENT} edition details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Nav variant="tabs" justify
                        activeKey={this.state.selectedTab}
                        onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                        <Nav.Item>
                            <Nav.Link eventKey="Tasks">Open tasks</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Sales">Sales</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Registrations">Registrations</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEditionId", "paramValue": null})}}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        openEdition: state.data.openEdition,
        profile: state.data.profile,
        tasks: state.data.tasks,
        sales: state.data.sales,
        users: state.data.users,
        contactConfig: state.data.contactConfig,
        productConfig: state.data.productConfig
    }
}

export default connect(mapStateToProps)(EventEditionBrowseModal)