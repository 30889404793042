import { Component } from "react";
import { connect } from 'react-redux'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryBar, VictoryZoomContainer} from 'victory'

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"

class LiveStreamManager extends Component {
    state = {
        editionID: null,
        intervalID: null
    }

    getJsonFromUrl(url) {
        var query = url.substr(1);
        var result = {};
        query.split("&").forEach(function(part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }
    
    timer() {
        if (this.state.editionID && this.props.interface.liveStream && this.props.interface.liveStream.chatEnabled)
            this.props.dispatch (apiRequest({'endpoint': 'getEventEditionLive', 'editionId': this.state.editionID}, null, true))    
    }

    componentDidUpdate(prevProps) {
        //if ((!prevProps.urlParams.sID) && this.props.urlParams.sID) {
        //    this.props.dispatch (publicApiRequest({'endpoint': 'startLiveStream', 'sID': this.props.urlParams.sID}))
        //    this.setState({intervalID: setInterval(this.timer.bind(this), 60*1000)});
        //}
    }

    componentWillUnmount() {
        if (this.state.intervalID)
            clearInterval(this.state.intervalID);
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getProducts'}))
        var params = this.getJsonFromUrl (this.props.urlParams)
        if (params.editionID)
        {
            this.setState ({editionID: parseInt(params.editionID), intervalID: setInterval(this.timer.bind(this), 10*1000)})
            this.props.dispatch (apiRequest({'endpoint': 'getEventEditionLive', 'editionId': parseInt(params.editionID), 'initial': true}))
        }
    }


    render () {
        var that = this
        
        var announcements = this.props.interface.liveStream.comments ? this.props.interface.liveStream.comments.map (function (p, ic) {
            return <div key={"ann" + ic} className={"mb-1 ann" + (p.removed ? " removed":"")}>
                <h5><Button variant="secondary" onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'alterLiveCommentState', 'commentId': p.id}))}}>{!p.removed ? <i className={"fs-4 bi-check2"}></i>:<i className={"fs-4 bi-x"}></i>}</Button> {p.text} - <span className="meta">{p.by} ({p.date})</span> </h5>
            </div>
        }):null

        var chat = <div className="ChatItems">
            {this.props.interface.liveStream && this.props.interface.liveStream.editionCard ? <div><h1>{this.props.interface.liveStream.editionCard.fullName} <Button onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": that.state.editionID})}}>Edit</Button></h1></div>:null}
            {this.props.interface.liveStream && this.props.interface.liveStream.liveSessionHeader ? <h2>Session header: {this.props.interface.liveStream.liveSessionHeader}</h2>:null}
            {this.props.interface.liveStream && this.props.interface.liveStream.viewersNumber ? <h2>Connected: {this.props.interface.liveStream.viewersNumber}</h2>:null}
            <h4>Live chat {this.props.interface.liveStream && this.props.interface.liveStream.chatEnabled ? "enabled":"disabled"}:</h4>
            <div className="messages">
                {announcements}
            </div>
        </div>

        if (this.props.interface.liveStream && this.props.interface.liveStream.stats && this.props.interface.liveStream.stats.contactsOrder)
        {
            var sessionsData = []
            var tLines = 0
            for (var cid of this.props.interface.liveStream.stats.contactsOrder)
            {
                var ic = 1;
                for (var sess of this.props.interface.liveStream.stats.contactToSessions[cid])
                {
                    sessionsData.push ({x: (ic > 1 ? ("s. " + ic + ' '):"") + sess.contact.fullName, y0: moment (sess.firstUpdated ? sess.firstUpdated : sess.createdAt, 'DD-MM-YYYY hh:mm').toDate(), y:moment(sess.lastUpdated ? sess.lastUpdated : sess.createdAt, 'DD-MM-YYYY hh:mm').toDate()})
                    ic += 1
                    tLines += 1
                }
            }
        }

        return <div className="LiveStreamManager">
            <Row >
                {this.props.interface.liveStream?<Col md={6}><div dangerouslySetInnerHTML={{__html: this.props.interface.liveStream.streamCode}} /></Col>:null}
                <Col md={6}>{chat}</Col>
            </Row>
            {this.props.interface.liveStream && this.props.interface.liveStream.stats ? <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <VictoryChart
                        containerComponent={<VictoryZoomContainer/>}
                        theme={VictoryTheme.material}
                        height={200}
                    >
                        <VictoryAxis
                            tickFormat={(x) => ("0" + new Date(x).getHours()).slice(-2) + ":" + ("0" + new Date(x).getMinutes()).slice(-2) }
                            style={{tickLabels: { fontSize: 4 }, axisLabel: { fontSize: 4, fill:'red'}}}
                        />
                        <VictoryAxis dependentAxis
                            tickFormat={(y) => y }
                            style={{tickLabels: { fontSize: 4 }, axisLabel: { fontSize: 4, fill:'red'}}}
                        />
                        <VictoryLine
                            style={{
                            data: { stroke: "#c43a31" },
                            parent: { border: "1px solid #ccc"}
                            }}
                            data={this.props.interface.liveStream.stats.timings.map (function (d, ic) {
                                return { x: moment(d[0], 'DD-MM-YYYY hh:mm').toDate(), y:d[1]}
                            })}
                        />
                    </VictoryChart>
                </Col>
                <Col md={1}></Col>
                <Col md={1}></Col>
                <Col md={10}>
                    <h5>Total number of people: {this.props.interface.liveStream.stats.contactsOrder.length} (sessions: {tLines}) </h5>
                    <VictoryChart
                        containerComponent={<VictoryZoomContainer/>}
                        theme={VictoryTheme.material}
                        domainPadding={{ y: 10, x:10 }}
                        padding={{left:80, bottom:80}}
                        >
                        <VictoryAxis
                            tickFormat={(y) => y }
                            style={{tickLabels: { fontSize: 4 }, axisLabel: { fontSize: 4, fill:'red'}}}
                        />
                        <VictoryAxis dependentAxis
                            tickFormat={(x) => ("0" + new Date(x).getHours()).slice(-2) + ":" + ("0" + new Date(x).getMinutes()).slice(-2) }
                            style={{tickLabels: { fontSize: 4 }, axisLabel: { fontSize: 4, fill:'red'}}}
                        />
                        
                        <VictoryBar horizontal
                            barWidth={4}
                            style={{
                                data: { fill: "#c43a31" }
                            }}
                            data={sessionsData}
                        />
                    </VictoryChart>
                </Col>
            </Row>:null}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams
    }
}

export default connect(mapStateToProps)(LiveStreamManager)