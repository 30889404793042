import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Select from 'react-select';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import {dynamicSortMultiple, oValues, firstSortedValue} from "./_tools"
import moment from "moment"

class HolidaysBrowser extends Component {
    state = {
        respFilter: (this.props.profile && this.props.profile.fullName && (!this.props.profile.isHolidaysAdmin)) ? {value: this.props.profile.id, label:this.props.profile.fullName }:{value:-1, label:'-'},
        updFilter: {value:0, label:"All"},
        
    }

    holidaysList = []

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getHolidays'}))
    }

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }
    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    render () {
        var contents = null
        var that = this

        this.selOptionsDic = {
            'by': oValues (this.props.users).sort (dynamicSortMultiple('-enabled', '*fullName')).map (function (el) {
                return {value:el.id, label: el.enabled ? el.fullName : (el.fullName + ' (arch)')}
            }),
        }
        

        var selOptions = this.selOptionsDic
        

        this.holidaysList = oValues (this.props.holidays).sort (dynamicSortMultiple ('state', '-dateStartYMD'))

        var lines = this.holidaysList.map (function (p, ic) {
            if ((that.state.respFilter.value > 0) && ((p.collaboratorId <= 0) || (p.collaboratorId !== that.state.respFilter.value) ))
                return null
            
            if (that.state.updFilter.value > 0)
            {
                if (!p.dateStart)
                    return null
                if (p.state != 2)
                    return null
                var de = p.dateEnd ? p.dateEnd : p.dateStart
                var dn = moment ()
                var d0 = moment (p.dateStart, 'DD-MM-YYYY')
                var d1 = moment (de, 'DD-MM-YYYY')
                d0.set({hour:12,minute:0,second:0,millisecond:0})
                d1.set({hour:12,minute:0,second:0,millisecond:0})
                dn.set({hour:12,minute:0,second:0,millisecond:0})
                
                if ((that.state.updFilter.value === 1) && !( (dn >= d0) && (d1 >= dn) ))
                    return null
                if (that.state.updFilter.value === 2)
                {
                    var dw = moment().add(7, 'days')
                    dw.set({hour:12,minute:0,second:0,millisecond:0})
                    if ((d1 < dn) || (d0 > dw))
                        return null
                }
                if ((that.state.updFilter.value === 3) || (that.state.updFilter.value === 4))
                {
                    var d0m = d0.month() + d0.year()*12
                    var d1m = d1.month() + d1.year()*12
                    var dnm = dn.month() + dn.year()*12
                    if  (that.state.updFilter.value === 4)
                        dnm -= 1
                    if ((d0m != dnm) && (d1m != dnm))
                        return null
                }

            }
                
            return <tr key={"hd"+ic} className={"tableLine holidayStatus" + p.state} >
                {that.props.profile.isHolidaysAdmin ? <td>{p.createdAt}</td>:null}
                <td>{p.collaboratorId in that.props.users ? that.props.users[p.collaboratorId].fullName:""}</td>
                <td>{p.dateStart} {p.onlyAfternoon ? " afternoon ":""} {p.dateEnd ? ("- " + p.dateEnd):""} {p.onlyMorning ? " morning ":""}</td>
                <td>{p.stateLabel}</td>
                {that.props.profile.isHolidaysAdmin ? <td>{p.isPaid ? null:<i className={"fs-4 bi-check2"}/>}</td>:null}
                {that.props.profile.isHolidaysAdmin ? <td>{p.note}</td>:null}
                {that.props.profile.isHolidaysAdmin ? <td>{p.notifiedById > 0 ? that.props.users[p.notifiedById].fullName : ""}</td>:null}
                <td>
                    {that.props.profile.isHolidaysAdmin && (p.state === 1) ? <span>
                                                <Button variant="secondary" className="me-3" onClick={(e)=>{e.stopPropagation (); that.props.dispatch (apiRequest({'endpoint': 'changeHolidayState', 'holidayId': p.id, "state":3}))}}>Refuse</Button>
                                                <Button variant="secondary" className="me-3" onClick={(e)=>{e.stopPropagation (); that.props.dispatch (apiRequest({'endpoint': 'changeHolidayState', 'holidayId': p.id, "state":2}))}}>Accept</Button>
                                            </span>:null}
                    {that.props.profile.isHolidaysAdmin && (p.state === 2) ? <span>
                        <Button variant="secondary" className="me-3" onClick={(e)=>{e.stopPropagation (); that.props.dispatch (apiRequest({'endpoint': 'alterHolidayType', 'holidayId': p.id}))}}>Alter extra-légaux</Button>
                        <Button variant="secondary" className="me-3" onClick={(e)=>{e.stopPropagation (); that.props.dispatch (apiRequest({'endpoint': 'changeHolidayState', 'holidayId': p.id, "state":3}))}}>Refuse</Button>
                    </span>:null}
                    {((p.state !== 2) && (!p.isAbsence) && (p.collaboratorId === that.props.profile.id)) ? <Button variant="secondary" className="me-3" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingHolidayId", "paramValue": [p.id, p.isAbsence]})}}>Edit</Button>:null}
                    
                </td>
            </tr>
        })
        var updFilterOptions = [{value:0, label:"All"},{value:1, label:"Today"},{value:2, label:"Next 7D"}, {value:3, label:"Current month"}, {value:4, label:"Previous month"}]
        if (!that.props.profile.isHolidaysAdmin)
            updFilterOptions = updFilterOptions.slice (0,3) 
        contents = <div className="mt-3">
            <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingHolidayId", "paramValue": [0, false]})}}>+ Add</Button>
            {that.props.profile.isAdmin ? <Button className="float-end btn-secondary me-3" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingHolidayId", "paramValue": [0, true]})}}>Mark absence</Button>:null}
            <h3>Holidays</h3>
            <Table responsive className="table-hover listingTable">
                <thead>
                    <tr>
                        {that.props.profile.isHolidaysAdmin ? <th>Demand date</th>:null}
                        <th>Name</th>
                        <th>Dates</th>
                        <th>State</th>
                        {that.props.profile.isHolidaysAdmin ? <th>Extra-légaux</th>:null}
                        {that.props.profile.isHolidaysAdmin ? <th>Note</th>:null}
                        {that.props.profile.isHolidaysAdmin ? <th>By</th>:null}
                        <th>Actions</th>
                    </tr>
                    <tr>
                            {that.props.profile.isHolidaysAdmin ? <td></td>:null}
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="respFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["respFilter"]}
                                    options={[{value:0, label:"-"}].concat (selOptions['by'])}
                                    
                                />
                            </td>
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="updFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["updFilter"]}
                                    options={updFilterOptions}
                                />
                            </td>
                            <td></td>
                            {that.props.profile.isHolidaysAdmin ? <td></td>:null}
                            <td></td>
                            
                        </tr>
                </thead>
                <tbody>
                    {lines}
                </tbody>
            </Table>
        </div>
    

        return <div className="productsBrowser">
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        holidays: state.data.holidays,
        users: state.data.users,
        profile: state.data.profile,
    }
}

export default connect(mapStateToProps)(HolidaysBrowser)