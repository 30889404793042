import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Select from 'react-select';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";
import Creatable from 'react-select/creatable';


import moment from "moment"
import { dynamicSortMultiple, oValues, productValueLabel } from "./_tools";

class TasksBrowser extends Component {
    state = {
        selectedTab: "Tasks",
        cFilter: "",
        companyFilter: "",
        productsFilter: [],
        respFilter: (this.props.profile && this.props.profile.fullName && (!this.props.profile.isSuperAdmin)) ? {value: this.props.profile.id, label:this.props.profile.fullName }:{value:-1, label:''},
        hotFilter: {value:0, label:"All"},
        actFilter: {value:-1, label:"All"},
        nActFilter: {value:1, label:"By today"},
        updFilter: {value:0, label:"All"},
        proposalFilter: {value:0, label:"All"},
        isClosedFilter: {value:4, label:"No"},
        tTypeFilter: {value:0, label:"All"}
    }
    
    numberWithSpaces(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }

    tasksList = []
    selOptionsDic = {}

    componentDidUpdate(prevProps) {
        if ( (this.props.profile && this.props.profile.fullName && (!this.props.profile.isSuperAdmin)) && (! (prevProps.profile && prevProps.profile.fullName)))
            this.setState ({ respFilter: {value: this.props.profile.id, label:this.props.profile.fullName }})
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getTasksConfig'}))
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }

    render () {
        var contents = null
        var that = this
        this.tasksList = oValues (this.props.tasks).sort (dynamicSortMultiple('nextActionDateF'))
        this.selOptionsDic = {
            'by': oValues (this.props.users).sort (dynamicSortMultiple('-enabled', '*fullName')).map (function (el) {
                return {value:el.id, label: el.enabled ? el.fullName : (el.fullName + ' (arch)')}
            }),
            'product': oValues (this.props.productConfig.productsDic).filter(function(el) { return that.props.productConfig.editionsDic[el.idEventEdition].isCurrent }).sort(dynamicSortMultiple('*fullName')).map (function (el) {
                return {value:el.id, label: el.fullName}
            })
        }
        

        var selOptions = this.selOptionsDic
        
        if (this.state.selectedTab === 'Tasks')
        {
            var cTasks = this.tasksList
            var counter = 0
            var lines = cTasks.map (function (p, ic) {
                var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {fullName:'', idCompany:-1}
                if ((that.state.cFilter.length > 0) && ((p.idContact < 0) || (! pContact.fullName.toLowerCase().includes (that.state.cFilter.toLowerCase()) )))
                    return null
                
                if ((that.state.companyFilter.length > 0) &&  ((pContact.idCompany < 0) || ((! that.props.contactConfig.companiesDic [pContact.idCompany].shortName.toLowerCase().includes (that.state.companyFilter.toLowerCase()) ) && (! that.props.contactConfig.companiesDic [pContact.idCompany].name.toLowerCase().includes (that.state.companyFilter.toLowerCase()) ))))
                    return null

                if (that.state.productsFilter.length > 0)
                {
                    var hasSelProduct = false
                    for (var pF of that.state.productsFilter)
                    { 
                        hasSelProduct = hasSelProduct || ( productValueLabel(p.idProduct, that.props.productConfig).label.toLowerCase().includes (pF.label.toLowerCase()) )
                    }
                    if (!hasSelProduct)
                        return null
                }
                if ((that.state.respFilter.value > 0) && ((p.idUser < 0) || (p.idUser !== that.state.respFilter.value) ))
                    return null
                if (((that.state.hotFilter.value === 1) && (!p.hotState)) || ((that.state.hotFilter.value === 2) && (p.hotState)))
                    return null
                if ((that.state.actFilter.value >= 0) && (p.taskStep.value !== that.state.actFilter.value))
                    return null
                if ((that.state.proposalFilter.value === 1) && ((!p.proposalDate) || (!that.differenceInDaysFromTodayIsIn (p.proposalDate, 0, 0))))
                    return null
                if ((that.state.proposalFilter.value === 2) && ((!p.proposalDate) || (!that.differenceInDaysFromTodayIsIn (p.proposalDate, 0, 7))))
                    return null
                if ((that.state.updFilter.value === 1) && ((!p.updateDate) || (!that.differenceInDaysFromTodayIsIn (p.updateDate, 0, 0))))
                    return null
                if ((that.state.updFilter.value === 2) && ((!p.updateDate) || (!that.differenceInDaysFromTodayIsIn (p.updateDate, -7, 0))))
                    return null
                if ((that.state.nActFilter.value === 1) && ((!p.nextActionDate) || (!that.differenceInDaysFromTodayIsIn (p.nextActionDate, null, 0))))
                    return null
                if ((that.state.nActFilter.value === 2) && ((!p.nextActionDate) || (!that.differenceInDaysFromTodayIsIn (p.nextActionDate, 0, 7))))
                    return null
                if ((that.state.isClosedFilter.value === 1) && ((!p.closeDate) || (!that.differenceInDaysFromTodayIsIn (p.closeDate, 0, 0))))
                    return null
                if ((that.state.isClosedFilter.value === 2) && ((!p.closeDate) || (!that.differenceInDaysFromTodayIsIn (p.closeDate, -7, 0))))
                    return null
                if ((that.state.isClosedFilter.value === 3) && ((!p.closeDate)))
                    return null
                if ((that.state.isClosedFilter.value === 4) && ((p.closeDate)))
                    return null

                if ((that.state.tTypeFilter.value === 1) && (!p.isSalesTask))
                    return null
                if ((that.state.tTypeFilter.value === 2) && (p.isSalesTask))
                    return null


                counter += 1
                
                return <tr className={p.hotState ? "task hot": "task"} key={"task"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                    <td>{(pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany].shortName : null}</td>
                    <td>{pContact.fullName}</td>
                    <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).label : ""}</td>
                    {that.props.profile && that.props.profile.isProgram ? null:<td>{that.numberWithSpaces(p.proposalAmount)} {p.proposalDate}</td>}
                    {that.props.profile && that.props.profile.isProgram ? null:<td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>}
                    <td>{p.taskStep.label}</td>
                    <td>{p.nextActionDate}</td>
                    <td>{p.updateDate}</td>
                    <td>{p.closeDate}</td>
                    <td>{(p.idUser > 0) ? that.props.users[p.idUser].fullName : null}</td>
                    <td>{p.isSalesTask ? <i className={"fs-4 bi-check2"}></i>:<i className={"fs-4 bi-x"}></i>}</td>
                </tr>
            })

            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": 0})}}>+ Add</Button>
                <h3>Tasks ({counter})</h3>
                <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Contact</th>
                            <th>Products</th>
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Proposal</th>}
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Is hot</th>}
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                            <th>Closed</th>
                            <th>Responsible</th>
                            <th>Sales task</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    name="companyFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["companyFilter"]}
                                    type="text"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    name="cFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["cFilter"]}
                                    type="text"
                                />
                            </td>
                            <td>
                                <Creatable  
                                    isMulti={true}
                                    menuPosition="fixed"
                                    name="productsFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["productsFilter"]}
                                    options={[{value:0, label:"-"}].concat (selOptions['product'])}
                                    placeholder="Product"
                                />
                            </td>
                            {that.props.profile && that.props.profile.isProgram ? null:<td>
                                <Select 
                                    menuPosition="fixed"
                                    name="proposalFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["proposalFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"Today"},{value:2, label:"Last 7D"}]}
                                />
                            </td>}
                            {that.props.profile && that.props.profile.isProgram ? null:<td>
                                <Select 
                                    menuPosition="fixed"
                                    name="hotFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["hotFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"Yes"},{value:2, label:"No"}]}
                                />
                            </td>}
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="actFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["actFilter"]}
                                    options={[{value:-1, label:"All"}, {value:0, label:"----"}].concat (that.props.taskSteps.map (function (el) {
                                        return {value:el.id, label: el.name}
                                    }))}
                                />
                            </td>
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="nActFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["nActFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"By today"},{value:2, label:"Next 7D"}]}
                                />
                            </td>
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="updFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["updFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"Today"},{value:2, label:"Last 7D"}]}
                                />
                            </td>
                            <td>
                            <Select 
                                    menuPosition="fixed"
                                    name="isClosedFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["isClosedFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"Today"},{value:2, label:"Last 7D"}, {value:3, label:'Yes'}, {value:4, label:'No'}]}
                                />
                            </td>
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="respFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["respFilter"]}
                                    options={[{value:0, label:"-"}].concat (selOptions['by'])}
                                    
                                />
                            </td>
                            <td>
                                <Select 
                                    menuPosition="fixed"
                                    name="tTypeFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["tTypeFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"Sales"},{value:2, label:"Not sales"}]}
                                />
                            </td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        return <div className="tasksBrowser">
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        tasks: state.data.tasks,
        isAdmin: state.data.profile.isAdmin,
        taskSteps: state.data.taskSteps,
        profile: state.data.profile,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        users: state.data.users,
    }
}

export default connect(mapStateToProps)(TasksBrowser)