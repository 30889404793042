import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import '../css/main.scss'

import Notification from "../interfaceParts/Notification"

import LoginForm from "../interfaceParts/LoginForm"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import {
  checkLoggedIn,
  setLoginScreenType
} from '../redux/actionsAuth'

class LoginPage extends Component {
    componentDidMount() {
        this.props.dispatch(checkLoggedIn())
        //if (this.props.location.search.includes("forget"))
        //    this.props.dispatch(setLoginScreenType('forget'))
    }

    render() {
        if (this.props.loggedIn)
            return (<Navigate to='/' />)
        
        return (
            <div className="App">
                <LoginForm/>  
                <LoadingSpinner show={this.props.loading} />
                <Notification/>
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.auth.error,
        loggedIn: state.auth.loggedIn,
        loading: state.interfaceParams.loading,
        username: state.auth.username
    }
}


export default connect(mapStateToProps)(LoginPage)


