import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import '../css/main.scss'

import Notification from "../interfaceParts/Notification"

import ResetPass from "../interfaceParts/ResetPass"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import {
  checkLoggedIn,
  redirectTo
} from '../redux/actionsAuth'

class ResetPassPage extends Component {
    componentWillMount() {
        this.props.dispatch(checkLoggedIn())
    }

    render() {
        if (this.props.shouldRedirectTo)
        {
            this.props.dispatch (redirectTo(null))

            return (<Navigate to={this.props.shouldRedirectTo} />)
        }    
        if (this.props.loggedIn)
            return (<Navigate to='/' />)

        return (
            <div className="App">
                <ResetPass secKey={document.location.search}/>  
                <LoadingSpinner show={this.props.loading} />
                <Notification/>
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.auth.error,
        loggedIn: state.auth.loggedIn,
        loading: state.interfaceParams.loading,
        username: state.auth.username,
        shouldRedirectTo: state.auth.shouldRedirectTo,
        
    }
}


export default connect(mapStateToProps)(ResetPassPage)


