import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, ON_LOAD_MAIL } from '../redux/actionsAPI';

class MailViewer extends Component {
    componentDidMount() {
        if (this.props.interface.viewingMailId > 0)
        {
            this.props.dispatch ({type: ON_LOAD_MAIL, openMail: {}})
            this.props.dispatch (apiRequest({'endpoint': 'getMail', 'mailId': this.props.interface.viewingMailId}))    
        }
    }

    render () {
        var that = this
        
        return <Modal size='xl'  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "viewingMailId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>Mail details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {[["Date", "date"], ['Sender', 'sender'], ['Subject', 'subject']].map (function (p,ic) {
                        return <Row key={"m" + ic}>
                                <Col md={3} className="text-end">{p[0]}:</Col>
                                <Col md={9}>{that.props.openMail[p[1]]}</Col>
                            </Row>
                    })}

                    {that.props.openMail.params ? <Row>
                            <Col md={12} dangerouslySetInnerHTML={{__html:that.props.openMail.params.content}} />
                        </Row>:null}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "viewingMailId", "paramValue": null})}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        openMail: state.data.openMail,
    }
}

export default connect(mapStateToProps)(MailViewer)