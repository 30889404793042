import Cookies from 'universal-cookie'


export const LOADING = 'LOADING'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const MODIFY_INTERFACE_PARAM = "MODIFY_INTERFACE_PARAM"


export function getAccessToken () {
    var auth = (new Cookies()).get("sysauth")
    if ((auth === undefined) || (auth === null))
        return undefined
    else return auth.access_token
}

export function setLoading (isLoading, loadingProgress) {
    return {
        type: LOADING,
        isLoading,
        loadingProgress
    }
}

export function setNotification (header=null, content=null, nclass='error') {
    return {
        type: SET_NOTIFICATION,
        header,
        content,
        nclass
    }
}

