import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import { oValues, dynamicSortMultiple } from './_tools'

class ContactImportModal extends Component {
    textFields = [["firstName", "First Name"],["lastName", "Last Name"], ["companyName", "Company"]]
    state =  Object.assign({ company: 0}, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 

    companiesList = []
    editionsList = []
    tagsList = []

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        var params = Object.assign(this.state, { [e.target.name]: e.target.value }) 
        this.props.dispatch (apiRequest({'endpoint': 'getOldContactsProposals', 'params': params}, null, true))    
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    

    render () {
        var that = this
        this.companiesList = oValues(this.props.contactConfig.companiesDic).sort (dynamicSortMultiple("*name"))
        var contents = <div>
                        <h4>Search contact by the following fields</h4>
                        {this.textFields.map (function (fn, ic) {
                                return <div className="control" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                <input
                                    id={fn[0]}
                                    className="form-control"
                                    name={fn[0]}
                                    onChange={that.handleInputChange}
                                    value={that.state[fn[0]]}
                                    type="text"
                                />
                            </div>
                            })}
                            
                        {that.props.suggestions.length > 0 ? 
                        <div>
                            <h4>Suggestions:</h4>
                            {that.props.suggestions.map (function (s, ic) {
                                return <Row className="mb-3" key={"sugg" + ic}>
                                    <Col md={3}>{s.firstName}</Col>
                                    <Col md={3}>{s.lastName}</Col>
                                    <Col md={3}>{s.companyName}</Col>
                                    <Col md={3}>
                                        <Button variant="primary" onClick={(e)=>{ that.props.dispatch (apiRequest({'endpoint': 'importContact', "oldContactId": s.id, 'companyId': that.state.company}, null)) }}>
                                            Import
                                        </Button>
                                    </Col>
                                    
                                    </Row>
                            })}
                            <div className="mb-3">
                                <div className="control">
                                    <label htmlFor="company" className="form-label">Company</label>
                                    <select 
                                        name="company"
                                        id="company"
                                        className="form-control"
                                        onChange={that.handleInputChange}
                                        value={that.state.company}
                                    >
                                        <option value={0}>Import from old database</option>
                                        {that.companiesList.map (function (el) {
                                            return <option value={el.id} key={"selCompany" + el.id}>{el.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>    
                        </div>:null}
                    </div>    
        
        return <Modal size={'xl'}  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "importingContactId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>Import contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "importingContactId", "paramValue": null})}}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        contactConfig: state.data.contactConfig,
        openContact: state.data.openContact,
        profile: state.data.profile,
        suggestions: state.data.oldContactSuggestions
    }
}

export default connect(mapStateToProps)(ContactImportModal)