import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Creatable from 'react-select/creatable';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';


import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';

class MailEditor extends Component {
    state =  { 'subject':"", editorState: EditorState.createEmpty()}
    
    componentDidMount() {
        
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
      };

    render () {
        var that = this
        return (
            <Modal size='xl' show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "sendingMailParams", "paramValue": null})}}>
                <Modal.Header closeButton className='mb-3'>
                    <Modal.Title>Compose an e-mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">Receivers: { that.props.interface.sendingMailParams.receivers.map (function (p, ic) { return <span className='receiver me-3' key={"rec" + ic}>{p.name}</span> }) }</label>
                    </div>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}} className='mb-3'>
                        <div className="mb-3">
                            <div className="control mb-3">
                                <label htmlFor="subject" className="form-label">Subject</label>
                                <input
                                    className="form-control"
                                    name="subject"
                                    onChange={that.handleInputChange}
                                    value={that.state.subject}
                                    type="text"
                                />
                            </div>
                        </div>    
                        <div className='mb-3'>
                            <Editor
                                editorState={this.state.editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange}
                            />
                        </div>
                    </form>    
                </Modal.Body>
                <Modal.Footer className='pb-3 pt-3 mb-3 mt-3'>
                <Button variant="secondary" className='mt-3' onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "sendingMailParams", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" className='mt-3' onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'sendPersonalMail', "params":{'subject': that.state.subject, 'content':draftToHtml(convertToRaw(that.state.editorState.getCurrentContent())), 'contactIDs': that.props.interface.sendingMailParams.receivers.map (function (p, ic) { return p.id }) }}, [{type:MODIFY_INTERFACE_PARAM, "paramName": "sendingMailParams", "paramValue": null},{type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": null}])) }}>
                    Send
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams
    }
}

export default connect(mapStateToProps)(MailEditor)