import Cookies from 'universal-cookie'

import {
    setLoading,
    setNotification,
    getAccessToken
} from './actions'

import { apiCommunication } from './actionsAPI'

//import * as actionsPlatforms from './actionsPlatforms'

export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOG_OUT = 'LOG_OUT'
export const CHECK_LOGGED_IN = 'CHECK_LOGGED_IN'
export const ASSERT_LOGGED_IN = 'ASSERT_LOGGED_IN'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const GET_TOKEN = 'GET_TOKEN'
export const SHOULD_REDIRECT_TO_LOGIN = 'SHOULD_REDIRECT_TO_LOGIN'
export const SHOULD_REDIRECT_TO = 'SHOULD_REDIRECT_TO'

export const SET_LOGIN_SCREEN_TYPE = 'SET_LOGIN_SCREEN_TYPE'

export const GLOBAL_URL = process.env["REACT_APP_BACKEND_URL"]

export function redirectToLogin () {
    return {
        type: SHOULD_REDIRECT_TO_LOGIN
    }
}

export function redirectTo (to) {
    return {
        type: SHOULD_REDIRECT_TO,
        to: to
    }
}

export function setLoginScreenType (screenType) {
    return {
        type: SET_LOGIN_SCREEN_TYPE,
        screenType
    }
}

export function loggedIn (username, auth) {
    return {
        type: LOGGED_IN,
        username,
        auth
    }
}

export function setLogout () {
    return {
        type: LOG_OUT
    }
}

export function refreshedToken (auth) {
    return {
        type: REFRESH_TOKEN,
        auth
    }
}

export function loginFailed () {
    return {
        type: LOGIN_FAILED
    }
}

export function checkLoggedIn () {
    return {
        type: CHECK_LOGGED_IN
    }
}

export function assertLoggedIn () {
    return {
        type: ASSERT_LOGGED_IN
    }
}

export function logout () {
    var mAuth = {
        clientId: process.env["REACT_APP_OAUTH_CLIENT_ID"],
        clientSecret: process.env["REACT_APP_OAUTH_CLIENT_SECRET"],
        accessTokenUri: GLOBAL_URL + 'o/revoke_token/',
    }

    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("token", getAccessToken())

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(mAuth.accessTokenUri, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    dispatch(setLogout())
                    dispatch(setLoading(false))
                    
                },
                (error) => {
                    console.log ("error")
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
                    
                }
            )
    }
}


export function auth (username, pass) {
    var mAuth = {
        clientId: process.env["REACT_APP_OAUTH_CLIENT_ID"],
        clientSecret: process.env["REACT_APP_OAUTH_CLIENT_SECRET"],
        accessTokenUri: GLOBAL_URL + 'o/token/',
    }

    var cUser = username.toLowerCase()
    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("grant_type", "password")
    data.append("username", cUser)
    data.append("password", pass)

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(mAuth.accessTokenUri, conf)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.error)
                    {
                        dispatch(loginFailed())
                        dispatch(setLoading(false))
                        dispatch(setNotification ("Error occured.", "The combination of username and password is not correct"))
                    }
                    else
                    {
                        dispatch(loggedIn(cUser, result))
                        dispatch(setLoading(false))
                    }
                },
                (error) => {
                    console.log (error)
                    dispatch(loginFailed())
                    dispatch(setLoading(false))
                    dispatch(setNotification ("Error occured.", "Please try again"))
                    
                }
            )
    }

}

export function authRefresh (desiredAction) {
    const cookies = new Cookies()
    var vauth = cookies.get("sysauth")
    var r_token = ""
    if ((vauth !== null) && (vauth !== undefined))
        r_token = vauth.refresh_token
    var mAuth = {
        clientId: process.env["REACT_APP_OAUTH_CLIENT_ID"],
        clientSecret: process.env["REACT_APP_OAUTH_CLIENT_SECRET"],
        accessTokenUri: GLOBAL_URL + 'o/token/',
    }

    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("grant_type", "refresh_token")
    data.append("refresh_token", r_token)

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    return dispatch => {
        dispatch(setLoading(true))
        fetch(mAuth.accessTokenUri, conf)
            .then(
                res => { 
                    if (res.status !== 200) 
                    {
                        dispatch(loginFailed())
                        dispatch(setLoading(false))
                        dispatch(setNotification ("Error occured.", "Need to login"))
                    }
                    return res.json()})
            .then(
                (result) => {
                    if (result.error)
                    {
                        dispatch(loginFailed())
                        dispatch(setLoading(false))
                        //dispatch(setNotification ("Error occured.", result.error))
                    }
                    else {
                        dispatch(refreshedToken(result))
                        console.log ('had desired action:')
                        console.log (desiredAction)
                        desiredAction.params[0].headers = new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
                        dispatch(apiCommunication (...desiredAction.params))
                    }
                },
                (error) => {
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again")
                }
            )
    }
}

export function forgetPass (mail) {
    var cUrl = "api/forgetPass"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ params: {mail: mail }}),
      headers: new Headers({ "Content-Type": "application/json" })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(GLOBAL_URL + cUrl, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    if (result.status === "ok")
                        dispatch(setNotification ("Check e-mail for further instructions", "If the e-mail is not in your inbox, please check the spam folder", "popup", "info"))
                    else
                        dispatch(setNotification ("Error occured.", result.error))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again")
                }
            )
    }
}

export function resetPass (newPass, searchPath) {
    var cUrl = "api/changePass"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ newPass: newPass, confirmationString: searchPath}),
      headers: new Headers({ "Content-Type": "application/json" })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(GLOBAL_URL + cUrl, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    if (result.status === "ok")
                    {
                        dispatch(redirectToLogin())
                        dispatch(setNotification ("Your password has changed", "You can login to the system", "popup", "info"))
                    }
                    else
                        dispatch(setNotification ("Error occured.", result.error))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again")
                }
            )
    }
}

