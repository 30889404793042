import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import '../css/main.scss'

import Notification from "../interfaceParts/Notification"

import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import { redirectTo } from '../redux/actionsAuth'

class EventRegistrationPage extends Component {
    componentDidMount() {
    }

    render() {
        if (this.props.shouldRedirectTo)
        {
            window.location.href = this.props.shouldRedirectTo
        }

        return (
            <div className="App inFrame">
                <div><h4>Your order has been treated!</h4></div>
                <LoadingSpinner show={this.props.loading} />
                <Notification/>
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.auth.error,
        loading: state.interfaceParams.loading,
        shouldRedirectTo: state.auth.shouldRedirectTo,
    }
}


export default connect(mapStateToProps)(EventRegistrationPage)


