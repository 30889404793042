import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import {LABEL_EVENT} from '../econf/cnf'

class EventEditModal extends Component {
    state = {
        name: "",
        shortName: ""
    }

    componentDidMount() {
        if (this.props.interface.editingEventId in this.props.productConfig.eventsDic)
        {
            var el = this.props.productConfig.eventsDic[this.props.interface.editingEventId]
            this.setState ({name: el.name, shortName:el.shortName})
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render () {
        var that = this
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingEventId > 0) ? "Edit " + LABEL_EVENT:"Create " + LABEL_EVENT}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                            <div className="control">
                                <label htmlFor="name" className="form-label">{LABEL_EVENT} name</label>
                                <input
                                    id="name"
                                    className="form-control"
                                    name="name"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.name}
                                    type="text"
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="name" className="form-label">{LABEL_EVENT} short name</label>
                                <input
                                    className="form-control"
                                    name="shortName"
                                    onChange={this.handleInputChange}
                                    value={this.state.shortName}
                                    type="text"
                                />
                            </div>
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'editEvent', "eventId": that.props.interface.editingEventId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        productConfig: state.data.productConfig
    }
}

export default connect(mapStateToProps)(EventEditModal)