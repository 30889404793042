import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';


import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"
import BDMDashboard from "./customViews/BDMDashboard";
import EBSDashboard from "./customViews/EBSDashboard";
import ADMDashboard from "./customViews/ADMDashboard";
import { LABEL_EVENT } from "../econf/cnf";
import { productValueLabel } from "./_tools";

class DashboardBrowser extends Component {
    state = {
        "newComment": "",
        "productFilter": "",
        "eventFilter": {value:0, label:"Select " + LABEL_EVENT},
        "editionFilter": {value:0, label:"Select edition"},
        "showType":{value:0, label:"All"},
        "showMember":{value:0, label:"All"},
    }

    numberWithSpaces(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }

    setEdition (eID)
    {
        if (eID in this.props.productConfig.editionsDic)
        {
            var cEdition = this.props.productConfig.editionsDic[eID]
            this.setState({ productFilter: cEdition.shortName, editionFilter: {value:cEdition.id, label:cEdition.subname} });
            return
        }
        this.setState({ productFilter: "" });
    }

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
        if (inp.name === "eventFilter")
        {
            this.setState({ productFilter: "", editionFilter: {value:0, label:"Select edition"}});
            if (e.value in this.props.productConfig.eventsDic)
            {
                var cEvent = this.props.productConfig.eventsDic[e.value]
                if (cEvent.idEditions.length > 0)
                {
                    for (var ed of cEvent.idEditions)
                        if (this.props.productConfig.editionsDic[ed].isCurrent)
                        {
                            this.setEdition (ed)
                            break
                        }
                }
            }
        }
        if (inp.name === "editionFilter")
        {
            this.setEdition (e.value)
        }
    };

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getDashboard'}))
    }

    render () {
        var that = this

        var todayTasks = null
        if (this.props.dashboard.todayTasks && (this.props.dashboard.todayTasks.length > 0))
        {
            var tLines = this.props.dashboard.todayTasks.map (function (pID, ic) {
                var p = that.props.tasks[pID]
                var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {'idCompany':0}
                console.log (p, pContact)
                return <tr className={p.hotState ? "task hot": "task"}  key={"ttask"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                    <td>{pContact.fullName}</td>
                    <td>{(pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany].name : null}</td>
                    <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).label : ""} </td>
                    {that.props.profile && that.props.profile.isProgram ? null:<td>{p.proposalAmount} {p.proposalDate}</td>}
                    {that.props.profile && that.props.profile.isProgram ? null:<td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>}
                    <td>{p.taskStep.label}</td>
                    <td>{p.nextActionDate}</td>
                    <td>{p.updateDate}</td>
                </tr>
            })
            todayTasks = <div className="mt-3 noPrint tasksRelated">
                <h3>Open tasks for today ({tLines.length})</h3>
                <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>Products</th>
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Proposal</th>}
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Is hot</th>}
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tLines}
                    </tbody>
                </Table>
            </div>
        }
        var weekTasks = null
        if (this.props.dashboard.weekTasks && (this.props.dashboard.weekTasks.length > 0))
        {
            var wLines = this.props.dashboard.weekTasks.map (function (pID, ic) {
                var p = that.props.tasks[pID]
                var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {'idCompany':0}
                
                return <tr className={p.hotState ? "task hot": "task"}  key={"ttask"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                    <td>{pContact.fullName}</td>
                    <td>{(pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany].name : null}</td>
                    <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).label : ""} </td>
                    {that.props.profile && that.props.profile.isProgram ? null:<td>{p.proposalAmount} {p.proposalDate}</td>}
                    {that.props.profile && that.props.profile.isProgram ? null:<td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>}
                    <td>{p.taskStep.label}</td>
                    <td>{p.nextActionDate}</td>
                    <td>{p.updateDate}</td>
                </tr>
            })
            weekTasks = <div className="mt-3 noPrint tasksRelated">
                <h3>Open tasks for this week ({wLines.length})</h3>
                <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>Products</th>
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Proposal</th>}
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Is hot</th>}
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {wLines}
                    </tbody>
                </Table>
            </div>
        }

        var announcements = this.props.announcements.map (function (p, ic) {
            return <div key={"ann" + ic} className="mb-1 ann">
                <h5>{p.text} - <span className="meta">{(p.idBy > 0) ? that.props.users[p.idBy].fullName : null} ({p.date})</span>  {(p.idBy === that.props.profile.id) ? <Button variant="link" onClick={(e)=>{ e.stopPropagation(); that.props.dispatch (apiRequest({'endpoint': 'removeAnnouncement', "annID": p.id})) }}><i className={"fs-4 bi-x"}></i></Button>:null}  </h5>

            </div>
        })
        
        
        return <div className="DashboardBrowser">
            <Row className="noPrint mb-3 border-bottom">
                <Col md={6}><h3>Hi {this.props.profile.first_name}</h3></Col>
                <Col md={6} className="text-end"><h3>{moment ().format('dddd LL')}</h3></Col>
            </Row>
            <Row className="noPrint">
                <Col md={8}>
                    {todayTasks}
                    {weekTasks}
                </Col>
                <Col md={4}>
                    <div className="CompanyAnnouncements">
                        <h4>Company-wide announcements:</h4>
                        <div className="control mb-2">
                                <input
                                    id="newComment"
                                    className="form-control"
                                    name="newComment"
                                    onChange={that.handleInputChange}
                                    value={that.state["newComment"]}
                                    type="text"
                                />
                                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'addAnnouncement', "text": that.state.newComment })) }}>
                                    Add
                                </Button>
                        </div>
                        {announcements}
                    </div>
                </Col>
            </Row>
            <ADMDashboard/>
            <BDMDashboard/>
            <EBSDashboard/>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        dashboard: state.data.dashboard,
        announcements: state.data.announcements,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        tasks: state.data.tasks,
        users: state.data.users
    }
}

export default connect(mapStateToProps)(DashboardBrowser)