import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import MDEditor from '@uiw/react-md-editor';




import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"
import { Converter } from "showdown"

import {dynamicSortMultiple, oValues, productValueLabel} from "./_tools"


class NewslettersBrowser extends Component {
    state = {
        selectedTab: "Articles",
        nameFilter: "",
        usedFilter: {label:'No', value:0},
        typeFilter: {value:-1, label:"All"},
        selectedArticles: {},
        selectedArticlesOrder: {},
        previewVisible: false,
        readyFullHTML: null,
        newsletterDate: moment().format("D MMMM YYYY"),
        showIntroAfter: false,
        newsletterType: {"value":-1, "label":"Type"},
        introText: ""
    }

    converter = new Converter()

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
        if (inp.name === 'newsletterType')
        {
            var nType = this.props.newslettersConfig.types[e.value]
            this.setState ({introText: nType.settings.introText})
        }
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    handleSelectedArticleOrderChange = e => {
        var ns = Object.assign({}, this.state.selectedArticlesOrder, {})
        ns[e.target.name] = e.target.value
        this.setState({selectedArticlesOrder: ns})
    };

    handleSelectedArticleChange = e => {
        var ns = Object.assign({}, this.state.selectedArticles, {})
        if (e.target.name in ns)
            delete ns[e.target.name]
        else
            ns[e.target.name] = true
        this.setState({selectedArticles: ns})
    };

    numberWithSpacesWithoutDecimals(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts[0]
        //return parts.join(".");
    }

    differenceInMonthsFromToday (d0Str) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = -d0.diff(d1, 'months')
        return diff
    }

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }

    newsletterHTMLGenerator () {
        var that = this

        if (that.state.newsletterType.value < 1)
            return "<h4>Please select the newsletter type above</h4>"

        var selectedArticlesIds = Object.keys (this.state.selectedArticles).sort (function(t1,t2) {
            var v1 = t1 in that.state.selectedArticlesOrder ? that.state.selectedArticlesOrder[t1] : 1
            var v2 = t2 in that.state.selectedArticlesOrder ? that.state.selectedArticlesOrder[t2] : 1
            return v1 > v2 ? 1 : -1
        })

        var ndate = that.state.newsletterDate
        var showIntroAfter = that.state.showIntroAfter

        var nType = that.props.newslettersConfig.types[that.state.newsletterType.value]
        
        var html = `<div style="max-width:700px;margin-left: auto; margin-right:auto;font-family: 'Helvetica', 'Arial', sans-serif;">
                <div style="padding-bottom: 20px; " > `
    
                html += `<a href="https://ebsummits.eu/"><div style="background-image: url('` + nType.settings.bannerURL + `'); width:700px; max-width: 100%; height:175px; max-height: 25vw; background-size: contain; text-align:right; "><span style="color: #fff; margin-right: min(15px, 2vw); display:inline-block; vertical-align:bottom; display: inline-block; font-size: 20px; font-size: min(20px,3vw); padding-top: min(118px,17vw);">` + ndate + `</span></div></a>`

        //if not isEHS:
        //    html += '''<a href="https://ebsummits.eu/"><div style="background-image: url('https://ebsummits.eu/media/images/banner_ebi_8_sep_22_4.original.png'); width:700px; max-width: 100%; height:175px; max-height: 25vw; background-size: contain; text-align:right; "><span style="color: #fff; margin-right: min(15px, 2vw); display:inline-block; vertical-align:bottom; display: inline-block; font-size: 20px; font-size: min(20px,3vw); padding-top: min(118px,17vw);">''' + ndate + '''</span></div></a>'''
        //else:
        //    html += '''<a href="https://ebsummits.eu/"><div style="background-image: url('https://ebsummits.eu/media/images/banner_ebi_8_sep_22_3.original.png'); width:700px; max-width: 100%; height:175px; max-height: 25vw; background-size: contain; text-align:right; "><span style="color: #fff; margin-right: min(15px, 2vw); display:inline-block; vertical-align:bottom; display: inline-block; font-size: 20px; font-size: min(20px,3vw); padding-top: min(118px,17vw);">''' + ndate + '''</span></div></a>'''


        html += `</div>`

        var mdhtml = that.converter.makeHtml(that.state.introText)
        if (!mdhtml)
            mdhtml = ""
        mdhtml = mdhtml.replace ("<img", '<img style="width:700px; max-width:100%;"')
        mdhtml = mdhtml.replace ("<hr />", '</div><div style="padding: 20px 10px; border-bottom:2px solid rgb(1, 70, 145); font-size:15px; " >')
        
        if (!showIntroAfter)
            html += `<div style="padding: 20px 10px; border-bottom:2px solid rgb(1, 70, 145); font-size:15px; " >` + mdhtml + "</div>"

        html += ` <div style="padding:20px 10px; border-bottom:2px solid rgb(1, 70, 145);" >
                        <span style="font-size: 16px; line-height: 120%;"><strong style="white-space: break-spaces;">This week's highlights </strong></span>
                        <ul style="margin-top: 20px;">`

        for (var artId of selectedArticlesIds)
            html += "<li>" + (that.props.newslettersConfig.articles[artId].titleUpper) + "</li>"

        html += `
                        </ul>
                    </div>
                    `


        for (var artId of selectedArticlesIds)
        {
            var art = that.props.newslettersConfig.articles[artId]
            html += `<div style="padding:20px 10px; border-bottom:2px solid rgb(1, 70, 145);" >
            <strong style="white-space: break-spaces; margin-bottom:20px; display: inline-block;">` + (art.titleRubrique ? (art.titleRubriqueUpper + ': '):'') + art.titleUpper + `</strong> 
            ` + art.content + `</div>`
        }

        if (showIntroAfter)
            html += `<div style="padding: 20px 10px; border-bottom:2px solid rgb(1, 70, 145);  font-size:15px; " >` + mdhtml + "</div>"

        html += `<div style="padding: 20px 10px; color:#333333; font-family: arial, helvetica, sans-serif; line-height: 120%;">
                        <div><span style="font-size: 12px; line-height: 120%;"><em><span style="color:#ff0000; line-height: 120%;">COPYRIGHT AGENCE EUROPE, OUR CONTENT IS NOT AVAILABLE FOR RE-DISSEMINATION!</span></em></span></div>
                        <div><span style="font-size: 12px; line-height: 120%;"><em><span style="color:#ff0000; line-height: 120%;">Agence Europe is only responsible for its own content.</span></em></span></div>
                        <div><span style="font-size: 12px; line-height: 120%;"><em><span style="color:#ff0000; line-height: 120%;">As current events evolve, Agence Europe sometimes has to update its content in online versions. </span></em></span></div>

                        <div style="background: #014691;text-align:center; padding: 12px; margin: 20px -10px;"><a style="font-size:19px; color:#fff; " href="https://www.ebsummits.eu">www.ebsummits.eu</a></div>
                        <div style="text-align:center"><a href="https://www.linkedin.com/company/european-business-summit"><img src="https://ebsummits.eu/static/img/linlogo.png" height="50px"/></a> <a href="https://twitter.com/ebsummiteurope"><img src="https://ebsummits.eu/static/img/xlogo.png" height="50px"/></a> <a href="https://www.instagram.com/ebsummiteurope/"><img src="https://ebsummits.eu/static/img/instlogo.png" height="50px"/></a></div>
                    </div> `

        html += `</div>`
        return html
    }

    
    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getNewsletters'}))
    }

    render () {
        var contents = null
        var that = this

        if (this.state.selectedTab === 'Types')
        {
            var lines = oValues(this.props.newslettersConfig.types).sort (dynamicSortMultiple("*name")).map (function (p, ic) {
                if ((that.state.nameFilter.length > 0) && (! (p.name).toLowerCase().includes (that.state.nameFilter.toLowerCase()) ))
                    return null
                
                return <tr role="button" key={"ntype"+ic} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterTypeId", "paramValue": p.id})}}>
                    <td>{p.name}</td>
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterTypeId", "paramValue": 0})}}>+ Add</Button>
                <h3>Newsletter types</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Title</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    name="nameFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["nameFilter"]}
                                    type="text"
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Newsletters')
            {
                var lines = oValues(this.props.newslettersConfig.confirmedNewsletters).sort (dynamicSortMultiple("-createdAt", "-id")).map (function (p, ic) {
                    //if ((that.state.nameFilter.length > 0) && (! (p.title).toLowerCase().includes (that.state.nameFilter.toLowerCase()) ))
                    //    return null
                    if (((that.state.typeFilter.value > 0) && (p.newsletterTypeId !== that.state.typeFilter.value)))
                        return null
                    return <tr role="button" key={"newsletter"+p.id} onClick={(e)=>{e.stopPropagation (); that.setState ({previewVisible:true, readyFullHTML:p.fullHTML});  }}>
                        <td>{p.createdAt}</td>
                        <td><p dangerouslySetInnerHTML={{__html:p.introText}}/></td>
                        <td>{that.props.newslettersConfig.types[p.newsletterTypeId].name}</td>
                    </tr>
                })
                contents = <div className="mt-3">
                    <h3>Newsletters</h3>
                    <Table responsive className="table-hover">
                        <thead>
                            <tr>
                                <th>Created</th>
                                <th>Intro</th>
                                <th>Type</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><Select 
                                    menuPosition="fixed"
                                    name="typeFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["typeFilter"]}
                                    options={[{value:-1, label:"All"}].concat ( oValues(this.props.newslettersConfig.types).sort (dynamicSortMultiple("*name")).map (function (cp, ic) { return {label:cp.name, value:cp.id} }))}
                                /></td>
                            </tr>
                        </thead>
                        <tbody>
                            {lines}
                        </tbody>
                    </Table>
                </div>
            }

        if (this.state.selectedTab === 'Articles')
            {
                var articlesList = oValues(this.props.newslettersConfig.articles).sort (dynamicSortMultiple("-dateYMD", "*title"))
                var lines = articlesList.map (function (p, ic) {
                    if ((that.state.nameFilter.length > 0) && (! (p.title).toLowerCase().includes (that.state.nameFilter.toLowerCase()) ))
                        return null
                    if (((that.state.usedFilter.value === 0) && p.used) || ((that.state.usedFilter.value !== 0) && (!p.used)))
                        return null
                    
                    return <tr role="button" key={"art"+p.id} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterArticleId", "paramValue": p.id})}}>
                        <td onClick={(e)=>{e.stopPropagation()}}><input
                                                    className="form-check-input"
                                                    name={p.id}
                                                    onChange={that.handleSelectedArticleChange}
                                                    checked={p.id in that.state.selectedArticles}
                                                    type="checkbox"
                                                /></td>
                        <td onClick={(e)=>{e.stopPropagation()}}>
                            <input
                                        className="form-control"
                                        name={p.id}
                                        onChange={that.handleSelectedArticleOrderChange}
                                        value={p.id in that.state.selectedArticlesOrder ? that.state.selectedArticlesOrder[p.id] : 1}
                                        type="number"
                                        style={{width:"60px"}}
                                    />
                        </td>
                        <td><p dangerouslySetInnerHTML={{__html:p.title}}/></td>
                        <td>{that.props.newslettersConfig.languages[p.langId].lang}</td>
                        <td>{p.date}</td>
                        <td>{p.used}</td>
                    </tr>
                })
                var htmlCode = that.newsletterHTMLGenerator()
                contents = <div className="mt-3">
                
                    <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterArticleId", "paramValue": 0})}}>+ Add</Button>
                    <Button className="float-end btn-secondary me-3" onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'newsletterArticlesFetch'}))}}>Get from APIs</Button>
                    <h3>Available articles</h3>
                    <Table responsive className="table-hover">
                        <thead>
                            <tr>
                                <th>Use now</th>
                                <th>Order</th>
                                <th>Title</th>
                                <th>Language</th>
                                <th>Date</th>
                                <th>Used</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <input
                                        className="form-control"
                                        name="nameFilter"
                                        onChange={that.handleInputChange}
                                        value={that.state["nameFilter"]}
                                        type="text"
                                    />
                                </td>
                                <td></td>
                                <td></td>
                                <td><Select 
                                    menuPosition="fixed"
                                    name="usedFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["usedFilter"]}
                                    options={[{label:'No', value:0}, {label:'Yes', value:1}]}
                                /></td>
                            </tr>
                        </thead>
                        <tbody>
                            {lines}
                        </tbody>
                    </Table>
                    {Object.keys(that.state.selectedArticles).length > 0 ? <div className="newsletterPreviewController">
                        <Button onClick={(e)=>{that.setState({previewVisible:true})}}>Show preview</Button>
                        </div>:null}
                    
                </div>  
            }
    


        
        return <div className="newslettersBrowser">
            <Nav variant="tabs" justify
                activeKey={this.state.selectedTab}
                onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                <Nav.Item>
                    <Nav.Link eventKey="Types">Types</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Newsletters">Newsletters</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Articles">Articles</Nav.Link>
                </Nav.Item>
            </Nav>
  
            {contents}
            {that.state.previewVisible ? <Modal size='xl' className='noPrint' scrollable={true} show={true} onHide={(e)=>{that.setState ({previewVisible:false, readyFullHTML:null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>Newsletter preview</Modal.Title>
                </Modal.Header>
                {that.state.readyFullHTML ?
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html:that.state.readyFullHTML}}/>
                </Modal.Body>
                :
                <Modal.Body>
                    <Row className="mb-3">
                        <Col md={4}>
                            <input
                                className="form-control"
                                name="newsletterDate"
                                onChange={that.handleInputChange}
                                value={that.state["newsletterDate"]}
                                type="text"
                            />
                        </Col>
                        <Col md={4}>
                            <div className="control mt-3">
                                <input
                                    className="form-check-input"
                                    name="showIntroAfter"
                                    onChange={that.handleCBChange}
                                    checked={that.state.showIntroAfter}
                                    type="checkbox"
                                />
                                <label htmlFor={"showIntroAfter"} className="form-check-label ms-2">Show intro after articles</label>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Select 
                                menuPosition="fixed"
                                name="newsletterType"
                                onChange={that.handleSelectChange}
                                value={that.state["newsletterType"]}
                                options={oValues(this.props.newslettersConfig.types).sort (dynamicSortMultiple("*name")).map (function (cp, ic) { return {label:cp.name, value:cp.id} })}
                            />
                        </Col>
                    </Row>
                    <Row>
                            <Col md={12}>
                                <MDEditor
                                    className="form-control"
                                    value={this.state.introText}
                                    onChange={(value)=>{this.setState({introText:value});}}
                                />
                            </Col>
                        </Row>
                    <div dangerouslySetInnerHTML={{__html:htmlCode}}/>
                </Modal.Body>}
                <Modal.Footer>
                    {(navigator.clipboard && window.isSecureContext) ?<Button variant='secondary' className='ms-3' onClick={(e)=>{ 
                            e.preventDefault ()
                            navigator.clipboard.writeText(that.state.readyFullHTML ? that.state.readyFullHTML : htmlCode);
                            }}><i className={"fs-4 bi-code-square"}></i></Button>:null}

                    {that.state.readyFullHTML ? null : <Button variant="primary" className='mt-3' onClick={(e)=>{ if (navigator.clipboard && window.isSecureContext) navigator.clipboard.writeText(htmlCode); this.props.dispatch (apiRequest({'endpoint': 'newsletterCreate', "newState":that.state, "htmlCode": htmlCode})) }}>
                        Copy code and mark as used
                    </Button>}
                </Modal.Footer>
            </Modal>:null}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        newslettersConfig: state.data.newslettersConfig,
        profile: state.data.profile,
    }
}

export default connect(mapStateToProps)(NewslettersBrowser)