import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';

import { MODIFY_INTERFACE_PARAM } from "../../redux/actions";

import moment from "moment"

class ADMDashboard extends Component {
    state = {
        "previewSales": null,
    }

    numberWithSpaces(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        if ((parts.length > 1) && (parts[1].length > 2))
            parts[1] = parts[1].substr (0,2)
        return parts.join(".");
    }

    render () {
        var that = this
        
        var salesUpdateLines = null
        if (this.props.dashboard.globalView && (this.props.dashboard.globalView.length > 0))
            salesUpdateLines = this.props.dashboard.globalView.map (function (edTs, ic) {
                var totals = [0,0,0,0]
                var lens = [0,0,0,0]
                for (var tType of [0,1,2,3])
                    if (edTs[1][tType])
                    {
                        var compIdToTotal = {}
                        for (var t of edTs[1][tType])
                        {
                            console.log (t)
                            //lens[tType] += 1
                            totals[tType] += t[0]
                            if (t[1].idCompany in compIdToTotal)
                                compIdToTotal[t[1].idCompany] += t[0]
                            else
                                compIdToTotal[t[1].idCompany] = t[0]
                        }
                        for (var cid in compIdToTotal)
                            if ((compIdToTotal[cid] > 0.01) || (compIdToTotal[cid] < -0.01))
                                lens[tType] += 1 
                    }


                return <tr key={"esu"+ic}>
                    <td>{edTs[0]}</td>
                    {that.props.profile.isExAdmin ? <td>{that.numberWithSpaces(totals[3]+totals[2]+totals[1]+totals[0])} eur</td>:null}
                    {that.props.profile.isExAdmin ? <td role="button" onClick={(e)=>{e.stopPropagation(); that.setState ({"previewSales": [ic, 2]})}}>{lens[2]}x : {that.numberWithSpaces(totals[2])} eur</td>:null}
                    {that.props.profile.isExAdmin ? <td role="button" onClick={(e)=>{e.stopPropagation(); that.setState ({"previewSales": [ic, 3]})}}>{lens[3]}x : {that.numberWithSpaces(totals[3])} eur</td>:null}
                    <td role="button" onClick={(e)=>{e.stopPropagation(); that.setState ({"previewSales": [ic, 1]})}}>{lens[1]}x : {that.numberWithSpaces(totals[1])} eur</td>
                    <td role="button" onClick={(e)=>{e.stopPropagation(); that.setState ({"previewSales": [ic, 0]})}}>{lens[0]}x : {that.numberWithSpaces(totals[0])} eur</td>
                </tr>
            })

        var modal = null
        if (that.state.previewSales && (that.state.previewSales[0] < that.props.dashboard.globalView.length))
        {
            var tType = that.state.previewSales[1]
            var edTs = that.props.dashboard.globalView[that.state.previewSales[0]]
            var contents = "No transactions"
            if (edTs[1][tType])
                contents = <Table responsive className="table-hover">
                                <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th>Amount</th>
                                        <th>Responsible</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {edTs[1][tType].map (function (tt,ict) {
                                        return <tr key={"ptc" + ict} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": tt[1].id})}}>
                                            <td>{(tt[1].idCompany > 0) ? that.props.contactConfig.companiesDic[tt[1].idCompany].name : null}</td>
                                            <td>{that.numberWithSpaces(tt[0])}</td>
                                            <td>{that.props.users[tt[1].idBy].fullName}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>

            modal = <Modal show={true} onHide={(e)=>{that.setState({previewSales:null})}}>
                        <Modal.Header closeButton>
                            <Modal.Title>{that.props.dashboard.globalView[that.state.previewSales[0]][0]} - {(tType === 2) ? "Paid" : ((tType === 1) ? "Sent":"Waiting")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {contents}
                        </Modal.Body>
                    </Modal>
        }
        
        
        return <div className="ADMDashboard">
            {(salesUpdateLines) ? <div className="mt-3 doPrint salesUpdate" id="salesUpdate">
                <h3>Recap - {moment().format("DD/MM/YYYY")}</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Edition</th>
                            {that.props.profile.isExAdmin ? <th>Total</th>:null}
                            {that.props.profile.isExAdmin ? <th>Paid</th>:null}
                            {that.props.profile.isExAdmin ? <th>Part.Paid</th>:null}
                            <th>Sent</th>
                            <th>Waiting</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesUpdateLines}
                    </tbody>
                </Table>
                {modal}
            </div>:null}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        dashboard: state.data.dashboard,
        announcements: state.data.announcements,
        users: state.data.users,
        contactConfig: state.data.contactConfig,
    }
}

export default connect(mapStateToProps)(ADMDashboard)