import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
    publicApiRequest,
    ON_LOAD_EDITION
} from '../redux/actionsAPI'

import {
    setNotification
} from '../redux/actions'
import { redirectTo } from '../redux/actionsAuth';

class NewsletterRegistration extends Component
{
    md5 = require('md5');
    textFields = [["firstName", "First Name"],["lastName", "Last Name"], ["email", "email"], ["jobTitle", "Position"], ["companyName", "Company"]]
    cbFields = [["ebiSubscribe", 'Subscribe to European Business Intelligence'], ["ehwSubscribe", 'Subscribe to European Health Weekly']]
    state = Object.assign({
        termsAccepted: false,
    }, ...this.textFields.map ((el) => ({ [el[0]]:"" })), ...this.cbFields.map ((el) => ({ [el[0]]:false }))) 

    
    getJsonFromUrl(url) {
        var query = url.substr(1);
        var result = {};
        query.split("&").forEach(function(part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    componentDidMount() {
        this.props.dispatch ({type: ON_LOAD_EDITION, openEdition: {id:-1}})
        //var params = this.getJsonFromUrl (this.props.urlParams)
    }


    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    handleSubmit = e => {
        e.preventDefault();
    }

    render() {
        var that = this
        var fieldsFilled = true
        return (
            (that.props.openEdition && that.props.openEdition.id) ? <div className="eventRegistrationForm">
                    
                        <div> 
                    
                            <div>
                                {this.textFields.map (function (fn, ic) {
                                    if ((!that.state[fn[0]]) || that.state[fn[0]].length < 2)
                                        fieldsFilled = false
                                    return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]} <span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="text"
                                        />
                                    </div>
                                })}
                                
                                {this.cbFields.map (function (fn, ic) {
                                    return <div className="control" key={"cbf" + ic}>
                                            <input
                                                className="form-check-input"
                                                name={fn[0]}
                                                onChange={that.handleCBChange}
                                                checked={that.state[fn[0]]}
                                                type="checkbox"
                                            />
                                            <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                        </div>
                                })}
                                        
                                <div className="control">
                                    <input
                                        className="form-check-input"
                                        name="termsAccepted"
                                        onChange={that.handleCBChange}
                                        checked={that.state["termsAccepted"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="termsAccepted" className="form-check-label ms-2"> I Accept EBS Terms and Conditions</label>
                                </div>
                                            
                                                    
                                <div className="control mt-1 buttonsContainer">
                                    <button className={"float-right btn btn-primary" + (this.state.termsAccepted && fieldsFilled  ? "" : " disabled")} disabled={!(this.state.termsAccepted && fieldsFilled )} onClick={(e)=>{
                                        this.props.dispatch (publicApiRequest({'endpoint': 'registerToNewsletter', 'newState': that.state}, {type: ON_LOAD_EDITION, openEdition: null}))       
                                        
                                    }}>Submit</button>
                                </div>
                            </div>
                        </div>
                        
                
                
                </div> : <div><h4>{that.props.inlineMessage}</h4></div>
        );
    }
}

function mapStateToProps(state) {
    return {
        inlineMessage: state.interfaceParams.inlineMessage,
        openEdition: state.data.openEdition
        
    }
}

export default connect(mapStateToProps)(NewsletterRegistration)
