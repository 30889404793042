import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";
import {LABEL_QNOTE} from "../econf/cnf"

import moment from "moment"
import { dynamicSortMultiple, oValues, productValueLabel } from "./_tools";

class WebSalesBrowser extends Component {
    state = {
        cFilter: "",
        companyFilter: "",
        productsFilter: [],
        dateFilterFrom: null,
        dateFilterFromT: null,
        dateFilterTo: null,
        dateFilterToT: null,
        paidFilter: {value:1, label:"Yes"},
        sortBy: {value:0, label:"Date"},
    }

    salesList = []
    productFilterList = []

    numberWithSpaces(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        if ((parts.length > 1) && (parts[1].length > 2))
            parts[1] = parts[1].substr (0,2)
        return parts.join(".");
    }

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getPublicSalesConfig'}))
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    sortResult (el1, el2, multiplier = 1) {
        if (!el1)
            return -multiplier
        if (!el2)
            return multiplier
        if (el1 < el2)
            return -multiplier
        if (el1 > el2)
            return multiplier
        return 0
    }


    render () {
        var contents = null
        var that = this
        this.salesList = oValues (this.props.publicSales)
        /*this.productFilterList = oValues (this.props.productConfig.productsDic).map (function (el) {
            return {value:el.id, label: el.fullName}
        }).concat (oValues (this.props.productConfig.editionsDic).map (function (el) {
            return {value:-el.id, label: el.shortName}
        })).sort(dynamicSortMultiple ('*label'))*/

        this.productFilterList = oValues (this.props.productConfig.editionsDic).map (function (el) {
            return {value:-el.id, label: el.shortName}
        }).sort(dynamicSortMultiple ('*label'))

        var selOptions = {
            'product': this.productFilterList
        }

        var cnt = 0
        var total = 0
        
        var lines = this.salesList.sort(function(t1,t2) {
            if (that.state.sortBy.value === 0)
                return that.sortResult(moment(t1.createdAt, 'DD-MM-YYYY') + t1.id, moment(t2.createdAt, 'DD-MM-YYYY') + t2.id, -1)
            if (that.state.sortBy.value === 1)
                return that.sortResult(t1.amount, t2.amount, -1)
            if (that.state.sortBy.value === 2)
                return that.sortResult(t1.contact.companyName ? t1.contact.companyName.toLowerCase : null, t2.contact.companyName ? t2.contact.companyName.toLowerCase : null, 1)
            if (that.state.sortBy.value === 3)
                return that.sortResult(t1.id, t2.id, -1)
        }).map (function (p, ic) {
            if ((that.state.cFilter.length > 0) && ((!p.contact.fullName) || (! p.contact.fullName.toLowerCase().includes (that.state.cFilter.toLowerCase()) )))
                return null
            if ((that.state.companyFilter.length > 0) &&  ((!p.contact.companyName) || (! p.contact.companyName.toLowerCase().includes (that.state.companyFilter.toLowerCase()) )))
                return null

            console.log (p.details.submission.newState.regOption.split('regStripe')[1])
            var prod = productValueLabel (parseInt(p.details.submission.newState.regOption.split('regStripe')[1]), that.props.productConfig)
            
            if (that.state.productsFilter.length > 0)
            {
                var hasSelProduct = false
                for (var pF of that.state.productsFilter)
                { 
                    hasSelProduct = hasSelProduct || (prod.label.toLowerCase().includes (pF.label.toLowerCase()) )
                }
                if (!hasSelProduct)
                    return null
            }
        
            if ((that.state.dateFilterFrom) && ((!p.createdAt) || (moment (p.createdAt, 'DD-MM-YYYY').toDate() < that.state.dateFilterFrom)))
                return null
            if ((that.state.dateFilterTo) && ((!p.createdAt) || (moment (p.createdAt, 'DD-MM-YYYY').toDate() > that.state.dateFilterTo)))
                return null
            if (((that.state.paidFilter.value === 1) && (!(p.isPaid))) || ((that.state.paidFilter.value === 2) && ((p.isPaid))))
                return null    
            cnt += 1    
            total += p.amount 

            var pContact = p.contact

            return <tr key={"sale"+ic}>
                <td></td>
                <td>{p.createdAt}</td>
                <td>{p.id}</td>
                <td>{prod.label}</td>
                <td className="text-end">{that.numberWithSpaces(p.amount)}</td>
                <td>{pContact.companyName}</td>
                <td>{pContact.fullName}</td>
                <td>{(p.isPaid) ? <i className={"fs-4 bi-check2"}></i>:null }</td>
            </tr>
        })
        contents = <div className="mt-3">
            <h3 className="transactionsTotal">Transactions ({cnt}{that.props.isExAdmin ? <span> - {that.numberWithSpaces(total)} eur</span>:null})</h3>
            <div className="filters">
                <h4>Filters:</h4>
                <div className="mb-3">
                    <div className="me-3 d-inline-block">
                        <DatePicker     
                            selected={that.state.dateFilterFrom} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => { that.setState ({dateFilterFrom:date, dateFilterFromT: date ? date.getTime(): date}); }}
                            placeholderText="From date"/>
                    </div>
                    <div className="me-3 d-inline-block">
                        <DatePicker
                            selected={that.state.dateFilterTo} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => { that.setState ({dateFilterTo:date, dateFilterToT: date.getTime()}); }}
                            placeholderText="To date"/>
                    </div>
                </div>
                <div className="mb-3">
                    <input
                        className="form-control me-3 d-inline-block"
                        name="companyFilter"
                        onChange={that.handleInputChange}
                        value={that.state["companyFilter"]}
                        type="text"
                        placeholder="Company"
                    />
                    <input
                        className="form-control me-3 d-inline-block"
                        name="cFilter"
                        onChange={that.handleInputChange}
                        value={that.state["cFilter"]}
                        type="text"
                        placeholder="Contact"
                    />
                    
                    <div className="d-inline-block me-3 ms-3 w300px"><Creatable  
                        isMulti={true}
                        menuPosition="fixed"
                        name="productsFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["productsFilter"]}
                        options={[{value:0, label:"-"}].concat (selOptions['product'])}
                        placeholder="Product"
                    /></div>
                    
                    
                </div>
                <div className="mb-3">
                    Paid: 
                    <Select 
                        menuPosition="fixed"
                        className="d-inline-block ms-3 me-3"
                        name="paidFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["paidFilter"]}
                        options={[{value:0, label:"All"},{value:1, label:"Yes"},{value:2, label:"No"}]}
                    />
                </div>
                <div className="mb-3">
                    Sort by: 
                    <Select 
                        menuPosition="fixed"
                        className="d-inline-block ms-3"
                        name="sortBy"
                        onChange={that.handleSelectChange}
                        value={that.state["sortBy"]}
                        options={that.props.isExAdmin ? [{value:0, label:"Date"},{value:1, label:"Amount"},{value:2, label:"Company"}, {value:3, label:"ID"}, {value:4, label:"Invoice NB"}]:[{value:0, label:"Date"},{value:2, label:"Company"}, {value:3, label:"ID"}]}
                    />
                </div>
                
            </div>
            <Table responsive className="table-hover salesTable">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>ID</th>
                        <th>Products</th>
                        <th className="text-end">HTVA</th>
                        <th>Company</th>
                        <th>Contact</th>
                        <th>Paid</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {lines}
                </tbody>
            </Table>
        </div>
        
        return <div className={"salesBrowser "  + (that.props.isExAdmin && (!that.props.interface.editingSaleId) ? "doPrint":"noPrint")}>
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        publicSales: state.data.publicSales,
        isAdmin: state.data.profile.isAdmin,
        isExAdmin: state.data.profile.isExAdmin,
        productConfig: state.data.productConfig,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(WebSalesBrowser)